<template>
	<div >
		<div class="bg-white">
			<div class="padding stu-info">
				<border-title titleName="基本信息" />
			</div>
			<div class="margin-top-xl flex flex-wrap-wrap">
				<el-col :span="6" class="margin-left-xl">
					<el-form ref="form" :model="form" label-width="80px">
						<el-form-item label="来源">
							<el-radio-group v-model="form.resource">
								<el-radio label="机构内"></el-radio>
								<el-radio label="机构外"></el-radio>
							</el-radio-group>
						</el-form-item>
			
						<el-form-item label="考试日期">
							<el-col :span="18">
								<el-date-picker type="date" placeholder="选择日期" v-model="form.date1" style="width: 100%;">
								</el-date-picker>
							</el-col>
			
						</el-form-item>
					</el-form>
				</el-col>
				<el-col :span="6">
					<el-form ref="form" :model="form" label-width="80px">
						<el-form-item label="项目">
							<el-col :span="12">
								<el-select v-model="form.region">
									<el-option label="区域一" value="shanghai"></el-option>
									<el-option label="区域二" value="beijing"></el-option>
								</el-select>
							</el-col>
							<el-col :span="6">
								<el-button style="margin-left: 10px;">新建项目</el-button>
							</el-col>
						</el-form-item>
			
						<el-form-item label="考试">
							<el-col :span="12">
								<el-select v-model="form.region1">
									<el-option label="区域一" value="shanghai"></el-option>
									<el-option label="区域二" value="beijing"></el-option>
								</el-select>
							</el-col>
							<el-col :span="6">
								<el-button style="margin-left: 10px;">新建考试</el-button>
							</el-col>
						</el-form-item>
					</el-form>
				</el-col>
			</div>
				
		</div>
		<div class="bg-white">
			<div class="padding stu-info">
				<div class="flex flex-wrap-wrap">
					<border-title titleName="录入成绩" />
					<el-button type="primary" size="mini" style="margin-left: 15px;" @click="editHandle">从班级选取学员</el-button>
					<span class="margin-left-sm"><i class="iconfont icon-yuandianxiao line-blue"></i>若学员缺考、未考、成绩为空即可</span>
				</div>
			</div>
		</div>
		<selectStudents :Visible="editVisible" @closepop="closeEditPop"></selectStudents>
	</div>
</template>

<script>
	import BorderTitle from "@/components/border-title/index.vue"
	import selectStudents from "../components/selectStudents.vue"
	export default {
		components: {
			BorderTitle,
			selectStudents
		},
		data() {
			return {
				form: {
					resource: '',
					date1: '',
					region: '',
					region1: ''
				}
			}
		},
		methods:{
			editHandle() { 
				this.editVisible = true;
			},
		}
	}
</script>

<style>
</style>
