<template>
	<el-dialog title="选择学员所在班级" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false"
		:close-on-press-escape="false" @closed="close" width="60%">
		<div style="height: 500px;" class="margin-tb">
			<div class="flex justify-content-between">
				<el-select v-model="region" placeholder="选择校区">
					<el-option label="驿都城校区" value="1"></el-option>
				</el-select>
				<el-col :span="6">
					<el-input v-model="stuInput" placeholder="请输入班级名称或教师名称搜索">
						<el-button slot="append" icon="el-icon-search"></el-button>
					</el-input>
				</el-col>
			</div>

			<div class=" flex aligin-items-center margin-top">
				<el-popover width="150" trigger="click">
					<el-checkbox-group v-model="schoolList">
						<el-checkbox class="padding-xs" label="班课" />
						<el-checkbox class="padding-xs" label="一对一" />
					</el-checkbox-group>
					<div slot="reference">
						<span>类型</span>
						<i class="el-icon-caret-bottom" />
						<span class="border-right" />
					</div>
				</el-popover>

				<el-popover width="250" trigger="click">
					<el-input v-model="classInput" placeholder="" size="mini" />
					<el-checkbox-group v-model="List">
						<el-checkbox class="padding-xs" :label="item.label" v-for="(item,index) of course"
							:key='index' />
					</el-checkbox-group>
					<div slot="reference">
						<span>项目</span>
						<i class="el-icon-caret-bottom" />
						<i class="el-icon-link" />

						<span class="text-grey">报读课程</span>
						<i class="el-icon-caret-bottom text-grey" />
						<span class="border-right" />
					</div>
				</el-popover>
				<el-popover width="150" trigger="click">
					<el-checkbox-group v-model="schoolList">
						<el-checkbox class="padding-xs" label="未开班" />
						<el-checkbox class="padding-xs" label="开班中" />
						<el-checkbox class="padding-xs" label="已结班" />
					</el-checkbox-group>
					<div slot="reference">
						<span>开班状态</span>
						<i class="el-icon-caret-bottom" />
						<span class="border-right" />
					</div>
				</el-popover>
			</div>
			<div style="margin-top: 10px">
				<as-list :initdataList="classList">
					<el-table-column type="selection" width="55"></el-table-column>
					<el-table-column align="center" prop="text1" label="班课/一对一"></el-table-column>
					<el-table-column align="center" prop="text2" label="课程"></el-table-column>
					<el-table-column align="center" prop="type" label="类型" width="90">
						 <span class="class-tag margin-left-sm">班</span>
					</el-table-column>
					<el-table-column align="center" prop="num" label="人数" width="60"></el-table-column>
					<el-table-column align="center" prop="status" label="状态" width="90"></el-table-column>
					<el-table-column align="center" prop="teacher" label="教师" width="120"></el-table-column>
					<el-table-column align="center" prop="data" label="开班日期" width="100"></el-table-column>
				</as-list>
			</div>
			<div class="flex justify-content-flexEnd">
				<el-button type="primary" @click='determine'>确定</el-button>
				<el-button class="myClient-left-30" @click="close">取消</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import {
		course
	} from '@/config/index'
	import AsList from "@/components/as-list";
	export default {
		props: {
			Visible: {
				type: Boolean,
				default: false,
			}
		},
		components: {
			AsList
		},
		data() {
			return {
				course,
				dialogVisible: false,
				groupNmae: '',
				region: '',
				stuInput: '',
				classInput: '',
				schoolList: '',
				List: [],
				classList: [{
					text1:'杨_沙湾校区  高一 1V2班',
					text2:'沙湾校区高一 1V2 ',
					num:'1/2',
					status:'开班中',
					teacher:'猪妹',
					data:'2021-01-19'
				}]
			}
		},
		watch: {
			Visible(val) {
				this.dialogVisible = val;
			}
		},
		created() {
			this.dialogVisible = this.Visible;
		},
		methods: {
			close() {
				this.$emit('closepop')
			},
		}
	}
</script>

<style scoped>
	.class-tag {
	  border-radius: 4px;
	  border: 1px solid #4a9efc;
	  padding: 2px 4px;
	  background-color: #dbebfe;
	  color: #4a9efc;
	}
</style>
